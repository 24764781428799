<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(saveCategory)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ modalTitle(name) }} <strong>Critério</strong></span>
					<span v-if="category.id">#{{ category.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<InputWithValidation class="mb-4" rules="required|min:3" type="text" label="Nome" size="is-medium" v-model="category.name" />

					<SelectWithValidation class="mb-4" rules="required" label="Tipo" size="is-medium" v-model="category.type" @change.native="updateType($event)">
						<option selected value="1">Categoria</option>
						<option value="2">Grupo</option>
					</SelectWithValidation>

					<span v-if="category.type == 1">
						<b-field label="Responsáveis" class="mb-4">
							<multiselect v-model="category.users" name="users" :options="managersAndMasters" label="full_name" track-by="full_name" multiple preserve-search :close-on-select="false" :clear-on-select="false" placeholder="" selectLabel="Selecionar" selectedLabel="Selecionado" deselectLabel="Remover">
								<span slot="noResult">Nenhum usuário encontrado</span>
							</multiselect>
						</b-field>

						<b-field label="Grupos" class="mb-4">
							<multiselect name="groups" v-model="category.groups" :options="groups" label="name" track-by="name" multiple preserve-search open-direction="top" :maxHeight="210" :close-on-select="false" :clear-on-select="false" placeholder="" selectLabel="Selecionar" selectedLabel="Selecionado" deselectLabel="Remover">
								<span slot="noResult">Nenhum grupo encontrado</span>
							</multiselect>
						</b-field>

						<div class="columns mb-4">
							<div class="column">
								<SelectWithValidation rules="required" label="Tipo de Pontuação" size="is-medium" v-model="category.equivalence"  @change.native="updateRule($event)">
									<option selected value="0">Padrão</option>
									<option value="1">Texto</option>
									<option value="2">Número</option>
								</SelectWithValidation>
							</div>
							<div class="column">
								<InputWithValidation rules="required|min:0|max:2" type="number" :label="category.equivalence == 0 ? 'Pontuação' : 'Quantidade de Pontos'" size="is-medium" v-model="category.weight" v-mask="'##'" @change.native="updateWeight" />
							</div>
						</div>

						<div v-if="category.equivalence != 0 && category.weight > 0" class="modal-rules scroll">
							<span v-for="e in category.equivalences" :key="e.id" class="modal-rules__item" :class="{ 'double' : category.equivalence == 2 }">
								<InputWithValidation rules="required|min:0" maxlength="2" size="is-small" class="modal-rules__index" v-model="e.index" />
								<span class="modal-rules__signal">=</span>
								<InputWithValidation v-if="category.equivalence == 1" rules="required|alpha|min:1|max:3" maxlength="3" size="is-small" v-model="e.from" />
								<span v-if="category.equivalence == 2" class="is-flex">
									<InputWithValidation rules="required|min:1|max:6" maxlength="6" size="is-small" v-model="e.from" />
									<span class="modal-rules__signal">até</span>
									<InputWithValidation rules="required|min:1|max:6" maxlength="6" size="is-small" v-model="e.to" />
								</span>
							</span>
							<small v-if="category.equivalence == 2" class="modal-rules__help">Utilize ponto para separar as casas decimais.</small>
						</div>
					</span>

					<b-field v-else label="Categorias" class="mb-4">
						<multiselect name="categories" v-model="category.groups" :options="groups" label="name" track-by="name" multiple open-direction="top" :maxHeight="210" preserve-search :close-on-select="false" :clear-on-select="false" placeholder="" selectLabel="Selecionar" selectedLabel="Selecionado" deselectLabel="Remover">
							<span slot="noResult">Nenhuma categoria encontrada</span>
						</multiselect>
					</b-field>

					<small class="modal-updated" v-if="category.updated_at">Última Modificação: {{ format(category.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Fechar</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">Salvar</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import { ValidationObserver } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		Multiselect,
		InputWithValidation,
		SelectWithValidation,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		},
		type: {
			type: Number,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			category: {
				name: '',
				type: 2,
				equivalence: '0',
				groups: [],
				weight: 0,
				equivalences: [],
				users: []
			},
			groups: [],
			categories: [],
			titlePrefix: '',
			wasCalled: false,
			managersAndMasters: []
		}
	},
	methods: {
		updateType(e) {
			this.category.type = e.target.value
			// console.log('updateType', this.category.type, e.target.value)
			this.category.groups = []
			this.getAllCategoryGroups()
		},
		updateRule(e) {
			this.category.equivalence = e.target.value
			this.category.equivalences = []
			this.prepareListEquivalences()
		},
		updateWeight() {
			this.prepareListEquivalences()
		},
		async saveCategory() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async store() {
			let route = this.category.type == 1 ? 'criteria' : 'criteria-group'
			let type = route == 'criteria' ? 'critério' : 'grupo'
			try {
				this.loading = true
				const response = await Api.post(`${route}/store`, this.category)
				const { status } = response
				if (status === 201) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/categories')
					successToast('O ' + type + ' foi <strong>cadastrado</strong> com sucesso!')
					eventHub.$emit('reload-categories')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('Ocorreu um <strong>erro</strong> ao criar o ' + type + '!')
				}
			} finally {
				this.loading = false
			}
		},
		async update() {
			let route = this.category.type == 1 ? 'criteria' : 'criteria-group'
			let type = route == 'criteria' ? 'critério' : 'grupo'
			try {
				this.loading = true
				const response = await Api.put(`${route}/update/${this.id}`, this.category)
				const { status } = response
				if (status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/categories')
					successToast('O ' + type + ' foi <strong>atualizado</strong> com sucesso!')
					eventHub.$emit('reload-categories')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					// const { message } = e.data
					errorToast('Ocorreu um <strong>erro</strong> ao atualizar o ' + type + '!')
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				let route = this.category.type == 1 ? 'criteria' : 'criteria-group'
				this.isOpening = true
				try {
					const response = await Api.get(route + `/findById/${this.id}`)
					const { status } = response

					if (status === 200) {
						const { data } = response
						this.category = data
						this.category.equivalence = `${this.category.equivalence}`
						this.isOpening = false
					}
				} catch (e) {
					if (!this.wasCalled) {
						this.category.type = this.category.type == 1 ? 2 : 1
						this.findById()
						this.wasCalled = true
					}
				}
			}
		},
		async getAllCategoryGroups() {
			try {
				let route = this.category.type == 2 ? 'criteria' : 'criteria-group'
				// console.log('route', route)
				// console.log('this.category.type', this.category.type, this.type)
				const response = await Api.get(route + '/findAll')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.groups = data
					// this.permission.route = JSON.parse(data.route)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		prepareListEquivalences() {
			if (['1', 1, '2', 2].includes(this.category.equivalence)) {
				if (this.category.equivalences.length > (parseInt(this.category.weight) - 1)) {
					for (let i = 0; i < this.category.equivalences.length; i++) {
						if (i > (parseInt(this.category.weight) - 1)) {
							this.category.equivalences.splice(i, 1)
						}
					}
				} else {
					for (let i = 0; i <= (parseInt(this.category.weight) - 1); i++) {
						if (!this.category.equivalences[i]) {
							this.category.equivalences.push({ index: `${i}`, from: null, to: null })
						}
					}
				}
			}
		},
		async getManagersAndMasters() {
			try {
				const response = await Api.post(`user/findByPermissionName`, { permissions: ['manager'] })
				if (response.status === 200) {
					this.managersAndMasters = response.data
				}
			} catch (e) {
				errorToast('Ocorreu um <strong>erro</strong> ao buscar os usuários')
			}
		}
	},
	mounted() {
		this.category.type = this.type
		this.titlePrefix = this.name == 'New' ? 'Novo' : 'Editar'
		this.getManagersAndMasters()
		this.findById()
		this.getAllCategoryGroups()
	}
}
</script>
